import useDate from '~/composables/useDate';

const { formatDateRange } = useDate();

export default function() {
    function makeMeta(event) {
        if (event.dateRange?.start && event.dateRange?.end) {
            return `${formatDateRange(event.dateRange.start, event.dateRange.end)}`;
        }
        if (event.duration) {
            return `Duration: ${event.duration}`;
        }
    }

    function makeLocation(event) {
        let string = '';

        if (event.entryType === 'educationAndEvents_onDemand_Entry') {
            string = 'Online';
            return string;
        }

        if (event.venue) {
            string += event.venue;
        } else if (event.address) {
            string += event.address;
        }

        if (event.venue && event.city) {
            string += ' • ';
        } else if (event.address && event.city) {
            string += ' • ';
        }

        if (event.city) {
            string += event.city;
        }

        return string;
    }

    return {
        makeMeta,
        makeLocation
    };
}


